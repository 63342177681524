import React from 'react'
import loadable from '@loadable/component'
import { Styled } from 'theme-ui'

import PrismCore from 'prismjs/components/prism-core'
import 'prismjs/components/prism-r'
import 'prismjs/components/prism-stata'
import 'prismjs/components/prism-markup'
import 'prismjs/components/prism-clike'
import 'prismjs/components/prism-javascript'
// import 'prismjs/components/prism-bash'

const Prism = loadable(() => import('@theme-ui/prism'))

const CodeBlock = props => (
  // eslint-disable-next-line react/jsx-pascal-case
  <Prism
    {...props}
    Prism={PrismCore}
    fallback={
      <Styled.pre>
        <code>{props.children}</code>
      </Styled.pre>
    }
  />
)

export default CodeBlock
